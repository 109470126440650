body {
  color: white;
}
div.container-full {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
}

.model-viewer-container {
  height: 100vh !important;
}

.model-viewer-container model-viewer {
  width: 100%;
  height: 100%;
}
