@keyframes pulseColor {
  0% {
    background-color: rgb(31, 47, 59);
  }
  100% {
    background-color: rgb(31, 47, 80);
  }
}

@keyframes pulseSize {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

html,
body {
  margin: 0;
  font-family: sans-serif;
}

main div#loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  color: #fff;
  z-index: 999;
}

main button {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  outline: none;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  padding: 0;
  margin: 0;
}

main#openar {
  width: 100%;
  height: 100vh;
  background-color: rgb(31, 47, 59);
}

main#openar h2 {
  padding: 20px;
  margin: 0;
}

main#openar .ar-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgb(31, 47, 59);
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  animation: pulseColor 2s infinite alternate-reverse;
  letter-spacing: 1px;
}

main#openar .ar-info img {
  margin: 20px 0;
  width: 40px;
  animation: pulseSize 3s infinite alternate-reverse;
}
